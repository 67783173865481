import "bootstrap-datepicker";

const Typeahead = require('typeahead.js');
const Switchery = require('switchery');
// Originally inspired by  David Walsh (https://davidwalsh.name/javascript-debounce-function)

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// `wait` milliseconds.
const debounce = (func, wait) => {
  let timeout;

  // This is the function that is returned and will be executed many times
  // We spread (...args) to capture any number of parameters we want to pass
  return function executedFunction(...args) {

    // The callback function to be executed after 
    // the debounce time has elapsed
    const later = () => {
      // null timeout to indicate the debounce ended
      timeout = null;
      
      // Execute the callback
      func(...args);
    };
    // This will reset the waiting every function execution.
    // This is the step that prevents the function from
    // being executed because it will never reach the 
    // inside of the previous setTimeout  
    clearTimeout(timeout);
    
    // Restart the debounce waiting period.
    // setTimeout returns a truthy value (it differs in web vs Node)
    timeout = setTimeout(later, wait);
  };
};

(function ($) {
    $(document).ready(function () {

        var datatableLang = {
            "decimal":        "",
            "emptyTable":     "Nessun dato disponibile nella tabella",
            "info":           "_TOTAL_ Risultati",
            "infoEmpty":      "Mostra da 0 a 0 di 0 risultati",
            "infoFiltered":   "(filtrato da _MAX_ voci totali)",
            "infoPostFix":    "",
            "thousands":      ",",
            "lengthMenu": 'Mostra <select>' +
                            '<option value="10">10</option>' +
                            '<option value="20">20</option>' +
                            '<option value="30">30</option>' +
                            '<option value="40">40</option>' +
                            '<option value="50">50</option>' +
                            '<option value="-1">All</option>' +
                            '</select>',
            "loadingRecords": "Caricamento in corso...",
            "processing":     "In lavorazione...",
            "search":         "Ricerca:",
            "searchPlaceholder": "Cerca nei tuoi ordini",
            "zeroRecords":    "Nessun record corrispondente trovato",
            "paginate": {
                "first":      " ",
                "last":       " ",
                "next":       " ",
                "previous":   " "
            },
            "aria": {
                "sortAscending":  ": activate to sort column ascending",
                "sortDescending": ": activate to sort column descending"
            },
        };


        $(".calendar").click(function () {
            $(this).parent().parent().next().show();
        });

        $(".calendar-content-btn").click(function () {
            $(this).parent().hide();
        });

        var $tagsInput = $('#tags_input');
        var $addCode = $('#add_code');
        var $el = $tagsInput.tagsinput('input');

        function initSearchLabel(){
            if ($tagsInput.length) {
                var selectedTags = $tagsInput.val()
                if(selectedTags.length>0){
                    $(".codice_title").removeClass("d-none")
                    $(".js-search-tags").removeClass("d-none")
                    $(".js-remove-tags").removeClass("d-none")
                }else{
                    $(".codice_title").addClass("d-none")
                    $(".js-search-tags").addClass("d-none")
                    $(".js-remove-tags").addClass("d-none")
                }
            }
        }

        initSearchLabel()

        $(document).on("keydown", "#tagsInputForm", function(event) { 
            return event.key != "Enter";
        });

        $('#tags_input').on('itemRemoved', function(event) {
            initSearchLabel()
          });

        $(document).on('click', ".aggiungia__button", function (e) {
            e.preventDefault();
            $tagsInput.tagsinput('add', $addCode.val());
            if($addCode.val().length>0){
               $(".codice_title").removeClass("d-none")
                $(".js-search-tags").removeClass("d-none")
                $(".js-remove-tags").removeClass("d-none")
            }

            setTimeout(function(){ 
                $addCode.val("");
            }, 300);

        });

        $(document).on('click', ".js-remove-tags", function (e) {
            e.preventDefault();
            $tagsInput.tagsinput('removeAll');
        });
        $( ".js-remove-tags" ).click(function() {
            $( "#tagsInputForm" ).submit();
            console.log("smthg2");
          });
            
        $('body').on("hide.bs.collapse show.bs.collapse", ".toggle-accordion", e => {
          $(e.target)
            .prev()
            .find(".accordion-icon")
            .toggleClass("fa-minus fa-plus");

            if (e.type === 'show') {
                var $card = $(e.target).closest('.scrollable');
                if ($card.length !== 0) {
                    setTimeout(function(){
                        $('html,body').animate({
                          scrollTop: $card.offset().top
                        }, 500); 
                    },300)

                }
            }
        });


        $('body').on('input', '.field__input', function() {
          var $field = $(this).closest('.field');
          if (this.value) {
            $field.addClass('field--not-empty');
          } else {
            $field.removeClass('field--not-empty');
          }
        });

        // calendario load more btn
        $(".calendario-box").slice(0, 6).show();
        if ($(".calendario-box:hidden").length != 0) {
          $("#jsLoadMore").show();
        }  

        $("#jsLoadMore").on('click', function (e) {
            e.preventDefault();
            $(".calendario-box:hidden").slice(0, 6).slideDown();
            if ($(".calendario-box:hidden").length == 0) {
                $("#jsLoadMore").fadeOut('slow');
            }
        });

        var table = $('#listinoRicambiTable').DataTable({
            pagingType: "full_numbers",
            dom: 'Bfrtipl',
            buttons: [
                {
                    extend: 'print',
                    className: 'btn btn-primary stampa__button hide-this mt-5 float-right',
                    text: '<img src="/wp-content/themes/daikin/include-frontend/public/assets/img/print-solid.png" alt=""> Stampa'
                }
            ],
            language: datatableLang,
        });
        table.buttons().container().appendTo($('#print_btn'));


        // Desktop version
        var tableOrder = $('#orderStatusTable').DataTable({
            pagingType: "full_numbers",
            scrollX: true,
            dom: 'Bfrtipl',
            buttons: [
                {
                    extend: 'excelHtml5',
                    sheetName: 'Orders',
                    className: 'btn download float-right',
                    text: '<i class="fas fa-download"></i> DOWNLOAD (excel)'
                }
            ],
            language: datatableLang
        });
        tableOrder.buttons().container().prependTo($('#jsDesktopTableOrder'));

        // mobile version
        var tableOrderMobile = $('#orderStatusTableMobile').DataTable({
            pagingType: "full_numbers",
            bSort : false,
            dom: 'Bfrtipl',
            buttons: [
                {
                    extend: 'excelHtml5',
                    sheetName: 'Orders',
                    className: 'btn download-button-mobile',
                    text: '<i class="fas fa-download"></i> DOWNLOAD (exel)'
                }
            ],
            language: datatableLang
        });
        tableOrderMobile.buttons().container().appendTo($('#download_btn_mobile'));

        $('#mobile-dt-search').on('keyup', function () {
            tableOrderMobile.search( this.value ).draw();
        });

        $('#CompleteListTable').DataTable({
            "pagingType": "full_numbers",
            "scrollX": true,
            language: datatableLang
        });

        $('#datetimepicker').datepicker();
        $('body').on('click', "#dataSelector", function () {
            $(this).parent().find(".datetimepicker-input").focus();
        });

        var $typeahead = $('#typeahead');
        var $typeaheadMobile = $('#typeaheadOther');
        var actionURL = $typeahead.attr('data-url');
        var baseSingleUrl = $typeahead.attr('data-base-single');

        $typeahead.on('blur', function(e) { 
            var that = $(this);
            setTimeout(function () { 
                that.next('.tt-menu').hide(); 
            }, 300);
        });

        $typeahead.on('focus', function(e) { 
            $(this).next('.tt-menu').show();
        });

        $typeahead.on('keyup', debounce(function(e) {
            var query = $(e.target).val();
            if (query != "") {
                $.ajax({
                    url: actionURL,
                    headers: {'Content-Type': 'application/json'},
                    data: {
                        action: 'daikin_search_api',
                        description: query,
                    },
                    dataType: 'json',
                    type: 'GET',
                    beforeSend: function(){
                        $("#typehead_wrapper").find('.search-spinner').show();
                        // Show preloader
                    },
                    success: function(data, textStatus, xhr) {
                        // console.log("success", data, textStatus, xhr);
                        if (textStatus == "success") {

                            var response = $.parseJSON(JSON.stringify(data));

                            var courses = Array();
                            courses.push(`<div class="tt-dataset">`);

                            if (response.length > 0) { 
                                $.each(response, function(index, val) {
                                    courses.push(`<div style="cursor: pointer" class="item tt-suggestion tt-selectable">
                                        <div class="item-title">
                                            <a href="${baseSingleUrl+'?corsi_id=' + val.id}">${val.name}</a>
                                        </div>
                                        <div class="item-description">
                                            <a href="${baseSingleUrl+'?corsi_id=' + val.id}">${val.description}</a>
                                        </div>
                                    </div>`);
                                });
                            } else {
                                courses.push(`<div style="cursor: pointer" class="item tt-suggestion tt-selectable">
                                        <div class="empty-message">
                                         Non ci sono corsi disponibili.
                                        </div>
                                    </div>`);
                            }

                            courses.push(`</div>`);

                            $typeahead.next('.tt-menu').html(courses.join(''));         
                        } else {

                        }
                    },
                    complete: function(xhr, textStatus) {
                        // Hide image preloader
                        $("#typehead_wrapper").find('.search-spinner').hide();
                        // console.log("complete", xhr, textStatus);
                    },
                    error: function(xhr, textStatus, errorThrown) {
                        // console.log("error", xhr, textStatus, errorThrown);
                    }
                });
            } else {
                $typeahead.next('.tt-menu').html('');
            }
        }, 600));


        // $typeahead.typeahead({
        //     minLength: 1,
        //     highlight: true,
        // }, {
        //     source: function (query, syncResults, asyncResults) {
        //         if (query === '') {
        //             syncResults([]);
        //         } else {
        //            $.ajax({
        //                url: actionURL,
        //                data: {
        //                    action: 'daikin_search_api',
        //                    description: query,
        //                },
        //                url: actionURL,
        //                headers: {'Content-Type': 'application/json'},
        //                dataType: "json",
        //                type: "GET",
        //                success: function (data) {
        //                    return asyncResults(data);
        //                }
        //            });
        //         }
        //     },
        //     name: 'lessons',
        //     displayKey: 'name',
        //     templates: {
        //         empty: [
        //           '<div class="empty-message">',
        //             'Non ci sono corsi disponibili.',
        //           '</div>'
        //         ].join('\n'),
        //         suggestion: function (val) {
        //             return `<div style="cursor: pointer" class="item">
        //                         <div class="item-title">
        //                             <a href="${baseSingleUrl+'?corsi_id=' + val.id}">${val.name}</a>
        //                         </div>
        //                         <div class="item-description">
        //                             <a href="${baseSingleUrl+'?corsi_id=' + val.id}">${val.description}</a>
        //                         </div>
        //                     </div>`;
        //         }
        //     }
        // });

        $typeaheadMobile.on('blur', function(e) { 
            var that = $(this);
            setTimeout(function () { 
                that.next('.tt-menu').hide(); 
            }, 300);
        });

        $typeaheadMobile.on('focus', function(e) { 
            $(this).next('.tt-menu').show();
        });

        $typeaheadMobile.on('keyup', debounce(function(e) {
            var query = $(e.target).val();
            if (query != "") {
                $.ajax({
                    url: actionURL,
                    headers: {'Content-Type': 'application/json'},
                    data: {
                        action: 'daikin_search_api',
                        description: query,
                    },
                    dataType: 'json',
                    type: 'GET',
                    beforeSend: function(){
                        $("#typehead_wrapper_other").find('.search-spinner').show();
                        // Show preloader
                    },
                    success: function(data, textStatus, xhr) {
                        // console.log("success", data, textStatus, xhr);
                        if (textStatus == "success") {

                            var response = $.parseJSON(JSON.stringify(data));


                            var courses = Array();
                            courses.push(`<div class="tt-dataset">`);

                            if (response.length > 0) { 
                                $.each(response, function(index, val) {
                                    courses.push(`<div style="cursor: pointer" class="item tt-suggestion tt-selectable">
                                        <div class="item-title">
                                            <a href="${baseSingleUrl+'?corsi_id=' + val.id}">${val.name}</a>
                                        </div>
                                        <div class="item-description">
                                            <a href="${baseSingleUrl+'?corsi_id=' + val.id}">${val.description}</a>
                                        </div>
                                    </div>`);
                                });
                            } else {
                                courses.push(`<div style="cursor: pointer" class="item tt-suggestion tt-selectable">
                                        <div class="empty-message">
                                         Non ci sono corsi disponibili.
                                        </div>
                                    </div>`);
                            }

                            courses.push(`</div>`);
                            $typeaheadMobile.next('.tt-menu').html(courses.join(''));         
                        } else {

                        }
                    },
                    complete: function(xhr, textStatus) {
                        // Hide image preloader
                        $("#typehead_wrapper_other").find('.search-spinner').hide();
                        // console.log("complete", xhr, textStatus);
                    },
                    error: function(xhr, textStatus, errorThrown) {
                        // console.log("error", xhr, textStatus, errorThrown);
                    }
                });
            } else {
                $typeaheadMobile.next('.tt-menu').html('');
            }
        }, 600));


        // $typeaheadMobile.typeahead({
        //     hint: true,
        //     minLength: 1,
        //     highlight: true,
        // }, {
        //     source: function (query, syncResults, asyncResults) {
        //         if (query === '') {
        //             syncResults([]);
        //         } else {
        //             $.ajax({
        //                 url: actionURL,
        //                 headers: {'Content-Type': 'application/json'},
        //                 data: {
        //                     action: 'daikin_search_api',
        //                     description: query,
        //                 },
        //                 dataType: "json",
        //                 type: "GET",
        //                 success: function (data) {
        //                     return asyncResults(data);
        //                 }
        //             });
        //         }
        //     },
        //     displayKey: 'name',
        //     templates: {
        //         empty: [
        //           '<div class="empty-message">',
        //             'Non ci sono corsi disponibili.',
        //           '</div>'
        //         ].join('\n'),
        //         suggestion: function (val) {
        //             return `<div style="cursor: pointer" class="item">
        //                         <div class="item-title">
        //                             <a href="${baseSingleUrl+'?corsi_id=' + val.id}">${val.name}</a>
        //                         </div>
        //                         <div class="item-description">
        //                             <a href="${baseSingleUrl+'?corsi_id=' + val.id}">${val.description}</a>
        //                         </div>
        //                     </div>`;
        //         }
        //     }
        // });

        var elem = document.querySelector('.js-switch');
        var elem2 = document.querySelector('.js-switch-mob');
        if (elem || elem2) {
            var init = new Switchery(elem);
            var init2 = new Switchery(elem2);
        }
    });
})(jQuery);

