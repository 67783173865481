window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require( 'bootstrap' );
    require( 'bootstrap-tagsinput' );
    require( 'switchery' );

    var JSZip = require( 'jszip' );

    require( 'pdfmake' );
    require( 'pdfmake/build/vfs_fonts' );
    require( 'datatables.net' );
    require( 'datatables.net-buttons-dt' );
    require( 'datatables.net-buttons/js/buttons.flash.js' );
    require( 'datatables.net-buttons/js/buttons.html5.js' );
    require( 'datatables.net-buttons/js/buttons.print.js' );
    window.JSZip = JSZip;
    require('slick-carousel/slick/slick.min.js');
    require('./screens/corsi.js');
    require('./search.js');
} catch (e) { }
