$(document).ready(function () {
    $('.slider__content').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: '<span class="prevArrow"><img src="/wp-content/themes/daikin/include-frontend/public/assets/img/arrow-right.svg" class="slick-prev"></span>',
        nextArrow: '<span class="nextArrow"><img src="/wp-content/themes/daikin/include-frontend/public/assets/img/arrow-right.svg" class="slick-next"></span>',
        responsive: [
            {
                breakpoint: 1601,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 993,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    });
});
